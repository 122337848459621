import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import { useDispatch } from 'react-redux';
import masterService from 'services/MasterService';

export const initialState = {
    loading: false,
    message: '',
    list: null,
    subloading: false,
    sublist: null,
}

export const officeTypeListAll = createAsyncThunk('/officetype/list', async (data, { rejectWithValue }) => {

    try {
        const response = await masterService.getOfficetype(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const officeTypeSubListAll = createAsyncThunk('/officetype/subList', async (data, { rejectWithValue }) => {

    try {
        const response = await masterService.getOfficetype(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const officeTypeSlice = createSlice({
    name: 'officeType',
    initialState,
    reducers: {
        officeTypeList: (state, action) => {
            state.loading = true
            state.list = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(officeTypeListAll.pending, (state) => {

                state.loading = false
            })
            .addCase(officeTypeListAll.fulfilled, (state, action) => {
                state.loading = true
                state.list = action.payload
            })
            .addCase(officeTypeSubListAll.pending, (state) => {

                state.subloading = false
            })
            .addCase(officeTypeSubListAll.fulfilled, (state, action) => {
                state.subloading = true
                state.sublist = action.payload
            })
    },
})

export const {
    officeTypeList
} = officeTypeSlice.actions

export default officeTypeSlice.reducer