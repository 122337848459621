import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import { useDispatch } from 'react-redux';
import masterService from 'services/MasterService';



export const initialState = {
    loading: false,
    message: '',
    list: null,
    sublist: null,
    subloading: false
}

export const versionListAll = createAsyncThunk('/version/list', async (data, { rejectWithValue }) => {

    try {
        const response = await masterService.getVersion(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const versionSubListAll = createAsyncThunk('/version/subList', async (data, { rejectWithValue }) => {

    try {
        const response = await masterService.getVersion(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})




export const versionSlice = createSlice({
    name: 'version',
    initialState,
    reducers: {

        versionList: (state, action) => {
            state.loading = true
            state.list = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(versionListAll.pending, (state) => {

                state.loading = false
            })
            .addCase(versionListAll.fulfilled, (state, action) => {
                state.loading = true
                state.list = action.payload
            })
            .addCase(versionSubListAll.pending, (state) => {

                state.subloading = false
            })
            .addCase(versionSubListAll.fulfilled, (state, action) => {
                state.subloading = true
                state.sublist = action.payload
            })
    },
})

export const {
    versionList


} = versionSlice.actions

export default versionSlice.reducer