import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'change-password',
        path: `${AUTH_PREFIX_PATH}/change-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'change-password',
        path: `${AUTH_PREFIX_PATH}/dashboards/change-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    },
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'dashboard.office',
        path: `${APP_PREFIX_PATH}/dashboards/office`,
        component: React.lazy(() => import('views/app-views/dashboards/office')),
    },

    {
        key: 'dashboard.views',
        path: `${APP_PREFIX_PATH}/dashboards/office/detail/:office_id`,
        component: React.lazy(() => import('views/app-views/dashboards/office/view')),
    },

    {
        key: 'dashboard.department',
        path: `${APP_PREFIX_PATH}/dashboards/department`,
        component: React.lazy(() => import('views/app-views/dashboards/department')),
    },

    {
        key: 'dashboard.designation',
        path: `${APP_PREFIX_PATH}/dashboards/designation`,
        component: React.lazy(() => import('views/app-views/dashboards/designation')),
    },
    {
        key: 'dashboard.role',
        path: `${APP_PREFIX_PATH}/dashboards/role`,
        component: React.lazy(() => import('views/app-views/dashboards/role')),
    },
    {
        key: 'dashboard.rolePermission',
        path: `${APP_PREFIX_PATH}//dashboards/rolesPermission/:role_id`,
        component: React.lazy(() => import('views/app-views/dashboards/role/permissions')),
    },
    {
        key: 'dashboard.permission',
        path: `${APP_PREFIX_PATH}/dashboards/permission`,
        component: React.lazy(() => import('views/app-views/dashboards/permission')),
    },
    {
        key: 'dashboard.coustomer',
        path: `${APP_PREFIX_PATH}/dashboards/officetype`,
        component: React.lazy(() => import('views/app-views/dashboards/coustomer/customerofficetype')),
    },
    {
        key: 'dashboard.user',
        path: `${APP_PREFIX_PATH}/dashboards/user`,
        component: React.lazy(() => import('views/app-views/dashboards/user/components/userlist')),
    },
    {
        key: 'dashboard.employee',
        path: `${APP_PREFIX_PATH}/dashboards/employee`,
        component: React.lazy(() => import('views/app-views/dashboards/employe')),
    },
    {
        key: 'dashboard.addemployee',
        path: `${APP_PREFIX_PATH}/dashboards/employee-add`,
        component: React.lazy(() => import('views/app-views/dashboards/employe/EmployeeAdd')),
    },
    {
        key: 'dashboard.employeeedit',
        path: `${APP_PREFIX_PATH}/dashboards/employee-edit/:employee_id`,
        component: React.lazy(() => import('views/app-views/dashboards/employe/EmployeeEdit')),
    },
    {
        key: 'dashboard.employeview',
        path: `${APP_PREFIX_PATH}/dashboards/employee-view/:employee_id`,
        component: React.lazy(() => import('views/app-views/dashboards/employe/views')),
    },
    {
        key: 'dashboard.employee.addTarget',
        path: `${APP_PREFIX_PATH}/dashboards/employee/addTarget/:employee_id`,
        component: React.lazy(() => import('views/app-views/dashboards/employe/AddTarget')),
    },
    {
        key: 'dashboard.employe.views',
        path: `${APP_PREFIX_PATH}/dashboards/employe/detail/:employee_id`,
        component: React.lazy(() => import('views/app-views/dashboards/employe/views')),
    },
    {
        key: 'dashboard.createtask',
        path: `${APP_PREFIX_PATH}/dashboards/task`,
        component: React.lazy(() => import('views/app-views/dashboards/meeting/components/createtask')),
    },
    {
        key: 'dashboard.createtaskView',
        path: `${APP_PREFIX_PATH}/dashboards/createtask/viewTask/:create_task_id`,
        component: React.lazy(() => import('views/app-views/dashboards/meeting/components/createtask/View')),
    },
    {
        key: 'dashboard.leadCreation',
        path: `${APP_PREFIX_PATH}/dashboards/leads`,
        component: React.lazy(() => import('views/app-views/dashboards/leadCreation/')),
    },
    {
        key: 'dashboard.leadCreation.addDetails',
        path: `${APP_PREFIX_PATH}/dashboards/lead/add`,
        component: React.lazy(() => import('views/app-views/dashboards/leadCreation/add')),
    },
    {
        key: 'dashboard.leadCreation.productLead',
        path: `${APP_PREFIX_PATH}/dashboards/lead/productLead/:lead_creation_id`,
        component: React.lazy(() => import('views/app-views/dashboards/leadCreation/details')),
    },
    {
        key: 'dashboard.leadCreation.productLeadDetails',
        path: `${APP_PREFIX_PATH}/dashboards/lead/productLead/Details/:product_lead_id`,
        component: React.lazy(() => import('views/app-views/dashboards/leadCreation/ViewProductLead')),
    },
    {
        key: 'dashboard.leadCreation.details',
        path: `${APP_PREFIX_PATH}/dashboards/lead/details/:lead_creation_id`,
        component: React.lazy(() => import('views/app-views/dashboards/leadCreation/ViewLead')),
    },
    {
        key: 'dashboard.leadCreation.edit',
        path: `${APP_PREFIX_PATH}/dashboards/lead/edit/:lead_creation_id`,
        component: React.lazy(() => import('views/app-views/dashboards/leadCreation/edit')),
    },
    {
        key: 'dashboard.standardMail',
        path: `${APP_PREFIX_PATH}/dashboards/standardMail`,
        component: React.lazy(() => import('views/app-views/dashboards/meeting/components/standardMail')),
    },
    {
        key: 'dashboard.standardMailView',
        path: `${APP_PREFIX_PATH}/dashboards/mom/standardMailView/:standerd_mailer_id`,
        component: React.lazy(() => import('views/app-views/dashboards/meeting/components/standardMail/View')),
    },
    {
        key: 'dashboard.salesStage',
        path: `${APP_PREFIX_PATH}/dashboards/salesStage`,
        component: React.lazy(() => import('views/app-views/dashboards/sales/salesStatus')),
    },
    {
        key: 'dashboard.salelevel',
        path: `${APP_PREFIX_PATH}/dashboards/customerLevel`,
        component: React.lazy(() => import('views/app-views/dashboards/sales/salelevel')),
    },
    {
        key: 'dashboard.kpi',
        path: `${APP_PREFIX_PATH}/dashboards/kpi-view/:kpi_id`,
        component: React.lazy(() => import('views/app-views/dashboards/kpi/viewkpi')),
    },
    {
        key: 'dashboard.kpi',
        path: `${APP_PREFIX_PATH}/dashboards/kpi`,
        component: React.lazy(() => import('views/app-views/dashboards/kpi')),
    },
    {
        key: 'dashboard.discountauthority',
        path: `${APP_PREFIX_PATH}/dashboards/discountauthority`,
        component: React.lazy(() => import('views/app-views/dashboards/sales/discountauthority')),
    },
    {
        key: 'dashboard.product',
        path: `${APP_PREFIX_PATH}/dashboards/product`,
        component: React.lazy(() => import('views/app-views/dashboards/product/addproduct')),
    },
    {
        key: 'dashboard.product.addproduct',
        path: `${APP_PREFIX_PATH}/dashboards/product/addproduct/importfile`,
        component: React.lazy(() => import('views/app-views/dashboards/product/addproduct/importfile')),
    },
    {
        key: 'dashboard.product.views',
        path: `${APP_PREFIX_PATH}/dashboards/product/detail/:product_id`,
        component: React.lazy(() => import('views/app-views/dashboards/product/addproduct/view')),
    },
    {
        key: 'dashboard.equipment',
        path: `${APP_PREFIX_PATH}/dashboards/equipment`,
        component: React.lazy(() => import('views/app-views/dashboards/product/equipment')),
    },
    {
        key: 'dashboard.category',
        path: `${APP_PREFIX_PATH}/dashboards/category`,
        component: React.lazy(() => import('views/app-views/dashboards/product/category')),
    },
    {
        key: 'dashboard.subcategory',
        path: `${APP_PREFIX_PATH}/dashboards/subCategory/:category_id`,
        component: React.lazy(() => import('views/app-views/dashboards/product/category/subcategory')),
    },
    {
        key: 'dashboard.version',
        path: `${APP_PREFIX_PATH}/dashboards/version`,
        component: React.lazy(() => import('views/app-views/dashboards/product/version')),
    },
    {
        key: 'dashboard.productType',
        path: `${APP_PREFIX_PATH}/dashboards/productType`,
        component: React.lazy(() => import('views/app-views/dashboards/product/productType')),
    },
    {
        key: 'dashboard.leadType',
        path: `${APP_PREFIX_PATH}/dashboards/leadType`,
        component: React.lazy(() => import('views/app-views/dashboards/sales/leadtype')),
    },
    {
        key: 'dashboard.orderProp',
        path: `${APP_PREFIX_PATH}/dashboards/orderProp`,
        component: React.lazy(() => import('views/app-views/dashboards/sales/orderprop')),
    },
    {
        key: 'dashboard.mom',
        path: `${APP_PREFIX_PATH}/dashboards/mom`,
        component: React.lazy(() => import('views/app-views/dashboards/meeting/components/mom')),
    },
    {
        key: 'dashboard.momView',
        path: `${APP_PREFIX_PATH}/dashboards/mom/viewMom/:mom_id`,
        component: React.lazy(() => import('views/app-views/dashboards/meeting/components/mom/View')),
    },
    {
        key: 'dashboard.visitscheduling',
        path: `${APP_PREFIX_PATH}/dashboards/visitscheduling`,
        component: React.lazy(() => import('views/app-views/dashboards/meeting/components/visitscheduling')),
    },
    {
        key: 'dashboard.visitschedulingView',
        path: `${APP_PREFIX_PATH}/dashboards/visitscheduling/viewvisitscheduling/:visit_scheduling_id`,
        component: React.lazy(() => import('views/app-views/dashboards/meeting/components/visitscheduling/View')),
    },
    {
        key: 'dashboard.coustomer',
        path: `${APP_PREFIX_PATH}/dashboards/customerTeamRole`,
        component: React.lazy(() => import('views/app-views/dashboards/coustomer/customerteamrole')),
    },
    {
        key: 'dashboard.officetype',
        path: `${APP_PREFIX_PATH}/dashboards/customertype`,
        component: React.lazy(() => import('views/app-views/dashboards/coustomer/customertype')),
    },
    {
        key: 'dashboard.customeraccountcreations',
        path: `${APP_PREFIX_PATH}/dashboards/customeraccountcreations`,
        component: React.lazy(() => import('views/app-views/dashboards/coustomer/customeraccountcreations')),
    },
    {
        key: 'dashboard.customeraccountcreations.import',
        path: `${APP_PREFIX_PATH}/dashboards/customeraccountcreations/importfile`,
        component: React.lazy(() => import('views/app-views/dashboards/coustomer/customeraccountcreations/ImportFile')),
    },
    {
        key: 'dashboard.customeraccountcreations.views',
        path: `${APP_PREFIX_PATH}/dashboards/customeraccountcreations/details/:customer_account_create_id`,
        component: React.lazy(() => import('views/app-views/dashboards/coustomer/customeraccountcreations/view')),
    },
    {
        key: 'dashboard.customermembercreation',
        path: `${APP_PREFIX_PATH}/dashboards/customermembercreation`,
        component: React.lazy(() => import('views/app-views/dashboards/coustomer/customermembercreation')),
    },
    {
        key: 'dashboard.customermembercreation.import',
        path: `${APP_PREFIX_PATH}/dashboards/customermembercreation/importfile`,
        component: React.lazy(() => import('views/app-views/dashboards/coustomer/customermembercreation/ImportFile')),
    },
    {
        key: 'dashboard.customeraccountcreations.lead',
        path: `${APP_PREFIX_PATH}/dashboards/leads/:customer_account_create_id`,
        component: React.lazy(() => import('views/app-views/dashboards/leadCreation')),
    },
    {
        key: 'dashboard.customeraccountcreations.customerMemberCreation',
        path: `${APP_PREFIX_PATH}/dashboards/customerMemberCreation/:customer_account_create_id`,
        component: React.lazy(() => import('views/app-views/dashboards/coustomer/customermembercreation')),
    },
    {
        key: 'dashboard.payment',
        path: `${APP_PREFIX_PATH}/dashboards/payment`,
        component: React.lazy(() => import('views/app-views/dashboards/PaymentandDelivery/payment')),
    },
    {
        key: 'dashboard.delivery',
        path: `${APP_PREFIX_PATH}/dashboards/delivery`,
        component: React.lazy(() => import('views/app-views/dashboards/PaymentandDelivery/delivery')),
    },
    {
        key: 'dashboard.offerCreation',
        path: `${APP_PREFIX_PATH}/dashboards/offerCreation`,
        component: React.lazy(() => import('views/app-views/dashboards/offerCreation')),
    },
    {
        key: 'dashboard.offerCreation.addDetails',
        path: `${APP_PREFIX_PATH}/dashboards/offer/add`,
        component: React.lazy(() => import('views/app-views/dashboards/offerCreation/add')),
    },
    {
        key: 'dashboard.offerCreation.edit',
        path: `${APP_PREFIX_PATH}/dashboards/offer/edit/:offer_creation_id`,
        component: React.lazy(() => import('views/app-views/dashboards/offerCreation/edit')),
    },
    {
        key: 'dashboard.offerCreation.views',
        path: `${APP_PREFIX_PATH}/dashboards/offerCreation/details/:offer_creation_id`,
        component: React.lazy(() => import('views/app-views/dashboards/offerCreation/viewoffer')),
    },


]