import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import { useDispatch } from 'react-redux';
import masterService from 'services/MasterService';



export const initialState = {
    loading: false,
    message: '',
    list: null,
    sublist: null,
    subloading: false
}

export const salesListAll = createAsyncThunk('/salelevel/list', async (data, { rejectWithValue }) => {

    try {
        const response = await masterService.getSalelevel(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const salesSubListAll = createAsyncThunk('/salelevel/subList', async (data, { rejectWithValue }) => {

    try {
        const response = await masterService.getSalelevel(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})




export const salesSlice = createSlice({
    name: 'sales',
    initialState,
    reducers: {

        salesList: (state, action) => {
            state.loading = true
            state.list = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(salesListAll.pending, (state) => {

                state.loading = false
            })
            .addCase(salesListAll.fulfilled, (state, action) => {
                state.loading = true
                state.list = action.payload
            })
            .addCase(salesSubListAll.pending, (state) => {

                state.subloading = false
            })
            .addCase(salesSubListAll.fulfilled, (state, action) => {
                state.subloading = true
                state.sublist = action.payload
            })
    },
})

export const {
    salesList


} = salesSlice.actions

export default salesSlice.reducer