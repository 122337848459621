import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import { useDispatch } from 'react-redux';
import masterService from 'services/MasterService';



export const initialState = {
    loading: false,
    message: '',
    list: null,
    sublist: null,
    subloading: false
}

export const customerTypeListAll = createAsyncThunk('/customertype/list', async (data, { rejectWithValue }) => {

    try {
        const response = await masterService.getCustomertype(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const customerTypeSubListAll = createAsyncThunk('/customertype/subList', async (data, { rejectWithValue }) => {

    try {
        const response = await masterService.getCustomertype(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})




export const customerTypeSlice = createSlice({
    name: 'customerType',
    initialState,
    reducers: {

        customerTypeList: (state, action) => {
            state.loading = true
            state.list = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(customerTypeListAll.pending, (state) => {

                state.loading = false
            })
            .addCase(customerTypeListAll.fulfilled, (state, action) => {
                state.loading = true
                state.list = action.payload
            })
            .addCase(customerTypeSubListAll.pending, (state) => {

                state.subloading = false
            })
            .addCase(customerTypeSubListAll.fulfilled, (state, action) => {
                state.subloading = true
                state.sublist = action.payload
            })
    },
})

export const {
    customerTypeList


} = customerTypeSlice.actions

export default customerTypeSlice.reducer