import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from "constants/AuthConstant";
import { useDispatch } from "react-redux";
import masterService from "services/MasterService";

export const initialState = {
  loading: false,
  message: "",
  list: null,
  sublist: null,
  subloading: false,
};

export const KpiListAll = createAsyncThunk(
  "kpi/list",
  async (data, { rejectWithValue }) => {
    try {
      const response = await masterService.getKpi(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const KpiSubListAll = createAsyncThunk(
  "kpi/subList",
  async (data, { rejectWithValue }) => {
    try {
      const response = await masterService.getKpi(data);
      return response.data;
    } catch (err) {
      return rejectWithValue(err.response?.data?.message || "Error");
    }
  }
);

export const sKpiSlice = createSlice({
  name: "Kpi",
  initialState,
  reducers: {
    KpiList: (state, action) => {
      state.loading = true;
      state.list = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(KpiListAll.pending, (state) => {
        state.loading = false;
      })
      .addCase(KpiListAll.fulfilled, (state, action) => {
        state.loading = true;
        state.list = action.payload;
      })
      .addCase(KpiSubListAll.pending, (state) => {
        state.subloading = false;
      })
      .addCase(KpiSubListAll.fulfilled, (state, action) => {
        state.subloading = true;
        state.sublist = action.payload;
      });
  },
});

export const { KpiList } = sKpiSlice.actions;

export default sKpiSlice.reducer;
