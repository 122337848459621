import fetch from 'auth/FetchMasterInterceptor'
import axios from 'axios';
import { API_BASE_URL } from 'configs/AppConfig';
import { AUTH_TOKEN } from 'constants/AuthConstant';
const masterService = {}
const jwtToken = localStorage.getItem(AUTH_TOKEN) || null;


masterService.getDepartment = function (data) {
  return fetch({
    url: '/department/list',
    method: 'post',
    data: data
  })
}


masterService.addDepartment = function (data) {
  return fetch({
    url: '/department/add',
    method: 'post',
    data: data
  })
}

masterService.editDepartment = function (data) {
  return fetch({
    url: '/department/update',
    method: 'post',
    data: data
  })
}

masterService.deleteDepartment = function (data) {
  return fetch({
    url: '/department/delete',
    method: 'post',
    data: data
  })
}

masterService.getDesignation = function (data) {
  return fetch({
    url: '/designation/list',
    method: 'post',
    data: data
  })
}

masterService.addDesignation = function (data) {
  return fetch({
    url: '/designation/add',
    method: 'post',
    data: data
  })
}

masterService.editDesignation = function (data) {
  return fetch({
    url: '/designation/update',
    method: 'post',
    data: data
  })
}

masterService.deleteDesignation = function (data) {
  return fetch({
    url: '/designation/delete',
    method: 'post',
    data: data
  })
}

masterService.getOffice = function (data) {
  return fetch({
    url: '/office/list',
    method: 'post',
    data: data
  })
}


masterService.addOffice = function (data) {
  return fetch({
    url: '/office/add',
    method: 'post',
    data: data
  })
}


masterService.editOffice = function (data) {
  return fetch({
    url: '/office/update',
    method: 'post',
    data: data
  })
}


masterService.deleteOffice = function (data) {
  return fetch({
    url: '/office/delete',
    method: 'post',
    data: data
  })
}


masterService.showOffice = function (data) {
  return fetch({
    url: '/office/show',
    method: 'post',
    data: data
  })
}


masterService.getTask = function (data) {
  return fetch({
    url: '/createtask/list',
    method: 'post',
    data: data
  })
}

masterService.addTask = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/createtask/add`, data, config);
}


masterService.editTask = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/createtask/update`, data, config);
}


masterService.deleteTask = function (data) {
  return fetch({
    url: '/createtask/delete',
    method: 'post',
    data: data
  })
}

masterService.getSalelevel = function (data) {
  return fetch({
    url: '/salelevel/list',
    method: 'post',
    data: data
  })
}

masterService.addSalelevel = function (data) {
  return fetch({
    url: '/salelevel/add',
    method: 'post',
    data: data
  })
}

masterService.editSalelevel = function (data) {
  return fetch({
    url: '/salelevel/update',
    method: 'post',
    data: data
  })
}

masterService.deleteSalelevel = function (data) {
  return fetch({
    url: '/salelevel/delete',
    method: 'post',
    data: data
  })
}


masterService.getSalestage = function (data) {
  return fetch({
    url: '/salestage/list',
    method: 'post',
    data: data
  })
}


masterService.addSalestage = function (data) {
  return fetch({
    url: '/salestage/add',
    method: 'post',
    data: data
  })
}

masterService.editSalestage = function (data) {
  return fetch({
    url: '/salestage/update',
    method: 'post',
    data: data
  })
}

masterService.deleteSalestage = function (data) {
  return fetch({
    url: '/salestage/delete',
    method: 'post',
    data: data
  })
}


masterService.getRole = function (data) {
  return fetch({
    url: '/role/list',
    method: 'post',
    data: data
  })
}


masterService.addRole = function (data) {
  return fetch({
    url: '/role/add',
    method: 'post',
    data: data
  })
}


masterService.editRole = function (data) {
  return fetch({
    url: '/role/update',
    method: 'post',
    data: data
  })
}


masterService.deleteRole = function (data) {
  return fetch({
    url: '/role/delete',
    method: 'post',
    data: data
  })
}

masterService.assignPermission = function (data) {
  return fetch({
    url: '/rolepermission/update',
    method: 'post',
    data: data
  })
}

masterService.getPermission = function (data) {
  return fetch({
    url: '/permission/list',
    method: 'post',
    data: data
  })
}


// masterService.editPermission = function (data) {
//   return fetch({
//     url: '/permission/update',
//     method: 'post',
//     data: data
//   })
// }

masterService.getTarget = function (data) {
  return fetch({
    url: '/target/list',
    method: 'post',
    data: data
  })
}

masterService.getEmployee = function (data) {
  return fetch({
    url: '/employee/list',
    method: 'post',
    data: data
  })
}


masterService.addEmployee = function (data) {
  return fetch({
    url: '/employee/create',
    method: 'post',
    data: data
  })
}


masterService.editEmployee = function (data) {
  return fetch({
    url: '/employee/update',
    method: 'post',
    data: data
  })
}


masterService.deleteEmployee = function (data) {
  return fetch({
    url: '/employee/delete',
    method: 'post',
    data: data
  })
}


masterService.showEmployee = function (data) {
  return fetch({
    url: '/employee/show',
    method: 'post',
    data: data
  })
}


masterService.getOfficetype = function (data) {
  return fetch({
    url: '/officetype/list',
    method: 'post',
    data: data
  })
}


masterService.addOfficetype = function (data) {
  return fetch({
    url: '/officetype/add',
    method: 'post',
    data: data
  })
}


masterService.editOfficetype = function (data) {
  return fetch({
    url: '/officetype/update',
    method: 'post',
    data: data
  })
}


masterService.deleteOfficetype = function (data) {
  return fetch({
    url: '/officetype/delete',
    method: 'post',
    data: data
  })
}


masterService.getEquipment = function (data) {
  return fetch({
    url: '/equipment/list',
    method: 'post',
    data: data
  })
}


masterService.addEquipment = function (data) {
  return fetch({
    url: '/equipment/add',
    method: 'post',
    data: data
  })
}


masterService.editEquipment = function (data) {
  return fetch({
    url: '/equipment/update',
    method: 'post',
    data: data
  })
}


masterService.deleteEquipment = function (data) {
  return fetch({
    url: '/equipment/delete',
    method: 'post',
    data: data
  })
}


masterService.getCategory = function (data) {
  return fetch({
    url: '/category/list',
    method: 'post',
    data: data
  })
}


masterService.addCategory = function (data) {
  return fetch({
    url: '/category/add',
    method: 'post',
    data: data
  })
}


masterService.editCategory = function (data) {
  return fetch({
    url: '/category/update',
    method: 'post',
    data: data
  })
}


masterService.deleteCategory = function (data) {
  return fetch({
    url: '/category/delete',
    method: 'post',
    data: data
  })
}


masterService.getDiscount = function (data) {
  return fetch({
    url: '/discountauthoritie/list',
    method: 'post',
    data: data
  })
}


masterService.addDiscount = function (data) {
  return fetch({
    url: '/discountauthoritie/add',
    method: 'post',
    data: data
  })
}


masterService.editDiscount = function (data) {
  return fetch({
    url: '/discountauthoritie/update',
    method: 'post',
    data: data
  })
}


masterService.deleteDiscount = function (data) {
  return fetch({
    url: '/discountauthoritie/delete',
    method: 'post',
    data: data
  })
}


masterService.getTeamrole = function (data) {
  return fetch({
    url: '/customerteamrole/list',
    method: 'post',
    data: data
  })
}


masterService.addTeamrole = function (data) {
  return fetch({
    url: '/customerteamrole/add',
    method: 'post',
    data: data
  })
}


masterService.editTeamrole = function (data) {
  return fetch({
    url: '/customerteamrole/update',
    method: 'post',
    data: data
  })
}


masterService.deleteTeamrole = function (data) {
  return fetch({
    url: '/customerteamrole/delete',
    method: 'post',
    data: data
  })
}



masterService.getLeadtype = function (data) {
  return fetch({
    url: '/leadtype/list',
    method: 'post',
    data: data
  })
}
masterService.addLeadtype = function (data) {
  return fetch({
    url: '/leadtype/add',
    method: 'post',
    data: data
  })
}

masterService.editLeadtype = function (data) {
  return fetch({
    url: '/leadtype/update',
    method: 'post',
    data: data
  })
}
masterService.deleteLeadtype = function (data) {
  return fetch({
    url: '/leadtype/delete',
    method: 'post',
    data: data
  })
}
masterService.getOrderProp = function (data) {
  return fetch({
    url: '/orderprobability/list',
    method: 'post',
    data: data
  })
}
masterService.addOrderProp = function (data) {
  return fetch({
    url: '/orderprobability/add',
    method: 'post',
    data: data
  })
}

masterService.editOrderProp = function (data) {
  return fetch({
    url: '/orderprobability/update',
    method: 'post',
    data: data
  })
}
masterService.deleteOrderProp = function (data) {
  return fetch({
    url: '/orderprobability/delete',
    method: 'post',
    data: data
  })
}

masterService.getKpi = function (data) {
  return fetch({
    url: 'kpi/list',
    method: 'post',
    data: data
  })
}
masterService.addSKpi = function (data) {
  return fetch({
    url: 'kpi/add',
    method: 'post',
    data: data
  })
}

masterService.addAproval = function (data) {
  return fetch({
    url: '/saleskpi/approve/reject',
    method: 'post',
    data: data
  })
}

masterService.editKpi = function (data) {
  return fetch({
    url: '/kpi/update',
    method: 'post',
    data: data
  })
}

masterService.deleteKpi = function (data) {
  return fetch({
    url: '/kpi/delete',
    method: 'post',
    data: data
  })
}



masterService.getSaleskpi = function (data) {
  return fetch({
    url: 'saleskpi/list',
    method: 'post',
    data: data
  })
}
masterService.addSalesKpi = function (data) {
  return fetch({
    url: 'saleskpi/add',
    method: 'post',
    data: data
  })
}

masterService.addAproval = function (data) {
  return fetch({
    url: 'saleskpi/approve/reject',
    method: 'post',
    data: data
  })
}

masterService.editSalesKpi = function (data) {
  return fetch({
    url: 'saleskpi/update',
    method: 'post',
    data: data
  })
}

masterService.deleteSalesKpi = function (data) {
  return fetch({
    url: 'saleskpi/delete',
    method: 'post',
    data: data
  })
}






masterService.getCustomertype = function (data) {
  return fetch({
    url: '/customertype/list',
    method: 'post',
    data: data
  })
}


masterService.addCustomertype = function (data) {
  return fetch({
    url: '/customertype/add',
    method: 'post',
    data: data
  })
}


masterService.editCustomertype = function (data) {
  return fetch({
    url: '/customertype/update',
    method: 'post',
    data: data
  })
}


masterService.deleteCustomertype = function (data) {
  return fetch({
    url: '/customertype/delete',
    method: 'post',
    data: data
  })
}


masterService.getCustomeraccountcreate = function (data) {
  return fetch({
    url: '/customeraccountcreate/list',
    method: 'post',
    data: data
  })
}
masterService.addCustomeraccountcreate = function (data) {
  return fetch({
    url: '/customeraccountcreate/add',
    method: 'post',
    data: data
  })
}
masterService.editCustomeraccountcreate = function (data) {
  return fetch({
    url: '/customeraccountcreate/update',
    method: 'post',
    data: data
  })
}


masterService.showCustomeraccountcreate = function (data) {
  return fetch({
    url: '/customeraccountcreate/show',
    method: 'post',
    data: data
  })
}

masterService.deleteCustomeraccountcreate = function (data) {
  return fetch({
    url: '/customeraccountcreate/delete',
    method: 'post',
    data: data
  })
}


masterService.getCustomermembercreate = function (data) {
  return fetch({
    url: '/customermemberceation/list',
    method: 'post',
    data: data
  })
}


masterService.addCustomermembercreate = function (data) {
  return fetch({
    url: '/customermemberceation/add',
    method: 'post',
    data: data
  })
}


masterService.editCustomermembercreate = function (data) {
  return fetch({
    url: '/customermemberceation/update',
    method: 'post',
    data: data
  })
}


masterService.deleteCustomermembercreate = function (data) {
  return fetch({
    url: '/customermemberceation/delete',
    method: 'post',
    data: data
  })
}

masterService.getProducttype = function (data) {
  return fetch({
    url: '/producttype/list',
    method: 'post',
    data: data
  })
}


masterService.editProducttype = function (data) {
  return fetch({
    url: '/producttype/update',
    method: 'post',
    data: data
  })
}
masterService.addProducttype = function (data) {
  return fetch({
    url: '/producttype/add',
    method: 'post',
    data: data
  })
}
masterService.deleteProducttype = function (data) {
  return fetch({
    url: '/producttype/delete',
    method: 'post',
    data: data
  })
}



masterService.getVersion = function (data) {
  return fetch({
    url: '/version/list',
    method: 'post',
    data: data
  })
}


masterService.addVersion = function (data) {
  return fetch({
    url: '/version/add',
    method: 'post',
    data: data
  })
}


masterService.editVersion = function (data) {
  return fetch({
    url: '/version/update',
    method: 'post',
    data: data
  })
}


masterService.deleteVersion = function (data) {
  return fetch({
    url: '/version/delete',
    method: 'post',
    data: data
  })
}


masterService.getProduct = function (data) {
  return fetch({
    url: '/product/list',
    method: 'post',
    data: data
  })
}
masterService.addProduct = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/product/add`, data, config);
}


masterService.editProduct = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/product/update`, data, config);
}


masterService.deleteProduct = function (data) {
  return fetch({
    url: '/product/delete',
    method: 'post',
    data: data
  })
}

masterService.getUser = function (data) {
  return fetch({
    url: '/user/list',
    method: 'post',
    data: data
  })
}


// masterService.addUser = function (data) {
//   return fetch({
//     url: '/user/add',
//     method: 'post',
//     data: data
//   })
// }
masterService.addUser = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/user/add`, data, config);
}

// masterService.editUser = function (data) {
//   return fetch({
//     url: '/user/update',
//     method: 'post',
//     data: data
//   })
// }

masterService.editUser = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/user/update`, data, config);
}

masterService.deleteUser = function (data) {
  return fetch({
    url: '/user/delete',
    method: 'post',
    data: data
  })
}



masterService.getStanderdmailer = function (data) {
  return fetch({
    url: '/standerdmailer/list',
    method: 'post',
    data: data
  })
}

masterService.addStanderdmailer = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/standerdmailer/add`, data, config);
}

masterService.editStanderdmailer = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/standerdmailer/update`, data, config);
}

masterService.deleteStanderdmailer = function (data) {
  return fetch({
    url: '/standerdmailer/delete',
    method: 'post',
    data: data
  })
}


masterService.getVisitscheduling = function (data) {
  return fetch({
    url: '/visitscheduling/list',
    method: 'post',
    data: data
  })
}


masterService.addVisitscheduling = function (data) {
  return fetch({
    url: '/visitscheduling/add',
    method: 'post',
    data: data
  })
}


masterService.editVisitscheduling = function (data) {
  return fetch({
    url: '/visitscheduling/update',
    method: 'post',
    data: data
  })
}


masterService.deleteVisitscheduling = function (data) {
  return fetch({
    url: '/visitscheduling/delete',
    method: 'post',
    data: data
  })
}


masterService.getProductlead = function (data) {
  return fetch({
    url: '/productlead/list',
    method: 'post',
    data: data
  })
}


masterService.addProductlead = function (data) {
  return fetch({
    url: '/productlead/add',
    method: 'post',
    data: data
  })
}


masterService.editProductlead = function (data) {
  return fetch({
    url: '/productlead/update',
    method: 'post',
    data: data
  })
}


masterService.deleteProductlead = function (data) {
  return fetch({
    url: '/productlead/delete',
    method: 'post',
    data: data
  })
}

masterService.getProductLeadDetails = function (data) {
  return fetch({
    url: '/editproduct/list',
    method: 'post',
    data: data
  })
}

masterService.getLeadcreation = function (data) {
  return fetch({
    url: '/leadcreation/list',
    method: 'post',
    data: data
  })
}

masterService.getLeadcreationDetails = function (data) {
  return fetch({
    url: '/editlead/list',
    method: 'post',
    data: data
  })
}

masterService.addLeadcreation = function (data) {
  return fetch({
    url: '/leadcreation/add',
    method: 'post',
    data: data
  })
}


masterService.editLeadcreation = function (data) {
  return fetch({
    url: '/leadcreation/update',
    method: 'post',
    data: data
  })
}


masterService.deleteLeadcreation = function (data) {
  return fetch({
    url: '/leadcreation/delete',
    method: 'post',
    data: data
  })
}


masterService.getDelivery = function (data) {
  return fetch({
    url: '/delivery/list',
    method: 'post',
    data: data
  })
}


masterService.addDelivery = function (data) {
  return fetch({
    url: '/delivery/add',
    method: 'post',
    data: data
  })
}


masterService.editDelivery = function (data) {
  return fetch({
    url: '/delivery/update',
    method: 'post',
    data: data
  })
}


masterService.deleteDelivery = function (data) {
  return fetch({
    url: '/delivery/delete',
    method: 'post',
    data: data
  })
}


masterService.getPayment = function (data) {
  return fetch({
    url: '/payment/list',
    method: 'post',
    data: data
  })
}

masterService.addPayment = function (data) {
  return fetch({
    url: '/payment/add',
    method: 'post',
    data: data
  })
}


masterService.editPayment = function (data) {
  return fetch({
    url: '/payment/edit',
    method: 'post',
    data: data
  })
}

masterService.deletePayment = function (data) {
  return fetch({
    url: '/payment/delete',
    method: 'post',
    data: data
  })
}


masterService.getMom = function (data) {
  return fetch({
    url: '/mom/list',
    method: 'post',
    data: data
  })
}


masterService.deleteMom = function (data) {
  return fetch({
    url: '/mom/delete',
    method: 'post',
    data: data
  })
}


masterService.editMom = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/mom/update`, data, config);
}


masterService.addMom = function (data) {
  const config = {
    headers: {
      "content-type": "multipart/form-data",
      "Authorization": `Bearer ${jwtToken}`,
    },
  };
  return axios
    .post(`${API_BASE_URL}/mom/add`, data, config);
}

masterService.getOffercreation = function (data) {
  return fetch({
    url: '/offercreation/list',
    method: 'post',
    data: data
  })
}


masterService.addOffercreation = function (data) {
  return fetch({
    url: '/offercreation/add',
    method: 'post',
    data: data
  })
}


masterService.editOffercreation = function (data) {
  return fetch({
    url: '/offercreation/update',
    method: 'post',
    data: data
  })
}


masterService.deleteOffercreation = function (data) {
  return fetch({
    url: '/offercreation/delete',
    method: 'post',
    data: data
  })
}


masterService.deleteOfferProduct = function (data) {
  return fetch({
    url: '/productoffer/delete',
    method: 'post',
    data: data
  })
}

masterService.addSaleskpi = function (data) {
  return fetch({
    url: '/saleskpi/add',
    method: 'post',
    data: data
  })
}


masterService.editSaleskpi = function (data) {
  return fetch({
    url: '/saleskpi/update',
    method: 'post',
    data: data
  })
}









export default masterService