import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import { useDispatch } from 'react-redux';
import masterService from 'services/MasterService';

export const initialState = {
    loading: false,
    message: '',
    list: null,
}

export const officeSetupListAll = createAsyncThunk('/office/list', async (data, { rejectWithValue }) => {

    try {
        const response = await masterService.getOffice(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})


export const officeSetupSlice = createSlice({
    name: 'officeSetup',
    initialState,
    reducers: {
        officeSetupList: (state, action) => {
            state.loading = true
            state.list = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(officeSetupListAll.pending, (state) => {

                state.loading = false
            })
            .addCase(officeSetupListAll.fulfilled, (state, action) => {
                state.loading = true
                state.list = action.payload
            })
           
    },
})

export const {
    officeSetupList
} = officeSetupSlice.actions

export default officeSetupSlice.reducer