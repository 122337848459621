import { combineReducers } from 'redux'
import theme from './slices/themeSlice'
import auth from './slices/authSlice'
import department from './slices/departmentSlice'
import officeType from './slices/officeTypeSlice'
import officeSetup from './slices/officeSetupSlice'
import designation from './slices/designationSlice'
import equipment from './slices/equipmentSlice'
import category from './slices/categorySlice'
import version from './slices/versionSlice'
import productType from './slices/productTypeSlice'
import customerTeamRole from './slices/customerTeamRoleSlice'
import customerType from './slices/customerTypeSlice'
import leadType from './slices/leadTypeSlice'
import orderProbability from './slices/orderProbability'
import role from './slices/roleSlice'
import sales from './slices/salesSlice'
import salesStage from './slices/salesStageSlice'
import discount from './slices/discountSlice'   
import Kpi from './slices/KpiSlices'
import accountCreation from './slices/accountCreationSlice'


const rootReducer = (asyncReducers) => (state, action) => {
    const combinedReducer = combineReducers({
        theme,
        auth,
        department,
        officeType,
        officeSetup,
        designation,
        equipment,
        category,
        version,
        productType,
        customerTeamRole,
        customerType,
        leadType,
        orderProbability,
        role,
        sales,
        salesStage,
        discount,
        Kpi,
       
        accountCreation,

        ...asyncReducers,
    })
    return combinedReducer(state, action)
}

export default rootReducer
