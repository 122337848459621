import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_DETAILS, AUTH_TOKEN, MENU_ITEMS } from 'constants/AuthConstant';
import { useDispatch } from 'react-redux';
import masterService from 'services/MasterService';



export const initialState = {
    loading: false,
    message: '',
    list: null,
    sublist: null,
    subloading: false
}

export const customerTeamRoleListAll = createAsyncThunk('/customerteamrole/list', async (data, { rejectWithValue }) => {

    try {
        const response = await masterService.getTeamrole(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})

export const customerTeamRoleSubListAll = createAsyncThunk('/customerteamrole/subList', async (data, { rejectWithValue }) => {

    try {
        const response = await masterService.getTeamrole(data)
        return response.data;
    } catch (err) {
        return rejectWithValue(err.response?.data?.message || 'Error')
    }
})




export const customerteamRoleSlice = createSlice({
    name: 'customerTeamRole',
    initialState,
    reducers: {

        customerTeamRoleList: (state, action) => {
            state.loading = true
            state.list = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(customerTeamRoleListAll.pending, (state) => {

                state.loading = false
            })
            .addCase(customerTeamRoleListAll.fulfilled, (state, action) => {
                state.loading = true
                state.list = action.payload
            })
            .addCase(customerTeamRoleSubListAll.pending, (state) => {

                state.subloading = false
            })
            .addCase(customerTeamRoleSubListAll.fulfilled, (state, action) => {
                state.subloading = true
                state.sublist = action.payload
            })
    },
})

export const {
    customerTeamRoleList


} = customerteamRoleSlice.actions

export default customerteamRoleSlice.reducer